<template>
  <v-container>
    <v-carousel
      hide-delimiter-background
      height="1000"
      :show-arrows="false"
      cycle
    >
      <v-carousel-item>
        <v-row>
          <v-col cols="12">
            <user-metrics />
          </v-col>
          <v-col cols="12">
            <company-metrics />
          </v-col>
        </v-row>
      </v-carousel-item>
      <v-carousel-item>
        <v-row>
          <v-col cols="12">
            <sales-per-insurer />
          </v-col>

          <v-col cols="12">
            <sales-graph />
          </v-col>
        </v-row>
      </v-carousel-item>
      <v-carousel-item
        ><voicemail-eventsper-consultant-graph />
      </v-carousel-item>
      <v-carousel-item>
        <referral-count />
      </v-carousel-item>
    </v-carousel>
    <div class="marquee-container">
      <div
        class="marquee"
        :style="{ transform: `translateX(-${marqueePosition}%)` }"
      >
        <span v-for="(stat, index) in statsList" :key="index">
          {{ stat }}
        </span>
      </div>
    </div>
  </v-container>
</template>

<script>
import CompanyMetrics from "../../components/Base/CompanyMetrics.vue";
import VoicemailEventsperConsultantGraph from "../../components/Base/Graphs/VoicemailEventsperConsultantGraph.vue";
import ReferralCount from "../../components/Base/ReferralCount.vue";
import SalesGraph from "../../components/Base/SalesGraph.vue";
import SalesPerInsurer from "../../components/Base/SalesPerInsurer.vue";
import UserMetrics from "../../components/Base/UserMetrics.vue";

export default {
  name: "Reporting",
  components: {
    UserMetrics,
    CompanyMetrics,
    SalesPerInsurer,
    SalesGraph,
    VoicemailEventsperConsultantGraph,
    ReferralCount,
  },
  data() {
    return {
      statsList: [
        "Total Revenue: $1,200,000",
        "New Customers: 150",
        "Customer Satisfaction: 95%",
        "Employee Count: 350",
        "Market Share: 23%",
      ],
      marqueePosition: 0,
    };
  },
  created() {
    this.startMarquee();
  },
  methods: {
    startMarquee() {
      setInterval(() => {
        this.marqueePosition += 1;
        if (this.marqueePosition > 100) {
          this.marqueePosition = 0;
        }
      }, 150); // Adjust speed by changing the interval time
    },
  },
};
</script>

<style scoped>
/* Marquee Container Styles */
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  background-color: #1976d2;
  color: white;
  padding: 10px 0;
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}

/* Marquee Animation */
.marquee {
  display: inline-block;
  white-space: nowrap;
  transition: transform 0.1s linear;
}

.marquee span {
  margin-right: 50px; /* Spacing between the stats */
  font-weight: bold;
}
</style>
